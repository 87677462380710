import tableGetters from "../../templates/table/getters";
import { isNil } from "lodash";

export default {
  ...tableGetters,
  getWebsiteById: state => id => state.website[id],
  timeframeMetricById: state => id =>
    !isNil(id) ? state.timeframeMetric[id] : {},
  displayedTimeframe: state => state.displayedTimeframe
};
